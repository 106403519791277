<template>
  <Web>
    <div class="container">
      <div v-if="step == 0">
        <h1>
          {{ $t('Mature Religiosity Scale (MRS): questionnaire religiosity/spirituality and coping') }}<br>
          <small>{{ $t('To be completed by adults and adolescents from 12 years old') }}</small>
        </h1>
        {{ $t('Dear Sir / Madam, dear youngster,') }}<br><br>
        {{ $t('Here you will find a questionnaire that can provide more insight into one\'s own religiosity and spirituality. The list starts with a dozen questions. This is followed by a list of 23 statements asking to what extent they apply to you. Try to assess honestly to what extent this is the case in daily life. Remember that there are no right or wrong answers and take the time to fill them in.') }}<br><br>
        {{ $t('Based on the results, you can determine which aspects you may want to pay attention to. For example, because there is an intertwining with problems or because you notice that there are aspects of your own religiosity/spirituality that could be strengthened or that you would like to gain more insight into.' )}}<br><br>
        {{ $t('It is also important that the answers from this list can be compared with the answers from other questionnaires in the context of scientific research. That is why it would be nice if you would also fill in the lists on page 2. However, don\'t feel any obligation in any way. Completing all questionnaires together is expected to take 30 to 40 minutes.') }}<br><br>
        {{ $t('Thank you in advance for your cooperation!') }}<br><br>
        {{ $t('Margreet de Vries-Schot, Psychiatrist-Psychotherapist and Theologian') }}<br><br>
        <div class="clearfix">
          <b-button variant="primary" size="lg" @click="nextPage" class="btn-block">{{ $t('To the questionnaire') }}</b-button>
        </div>
      </div>
      <div v-if="step == 1">
        <h1>{{ $t('Questionnaire religiosity/spirituality') }}</h1>
        <b-form-group :label="label.country">
          <b-form-input :state="validateState('country')" v-validate="{ required: true }" name="country" v-model="form.country" type="text"></b-form-input>
          <b-form-invalid-feedback>{{ veeErrors.first('country') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.age">
          <b-form-radio-group
            v-model="form.age"
            :options="options.age"
            name="age"
            :state="validateState('age')" v-validate="{ required: true }"
          ></b-form-radio-group>
          <b-form-invalid-feedback>{{ veeErrors.first('age') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.gender">
            <b-form-radio-group
              v-model="form.gender"
              :options="options.gender"
              name="gender"
              :state="validateState('gender')" v-validate="{ required: true }"
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('gender') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.marial_status">
            <b-form-radio-group
              v-model="form.marial_status"
              :options="options.marial_status"
              name="marial_status"
              :state="validateState('marial_status')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('marial_status') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.in_treatment">
            <b-form-radio-group
              v-model="form.in_treatment"
              :options="options.in_treatment"
              name="in_treatment"
              :state="validateState('in_treatment')" v-validate="{ required: true }"
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('in_treatment') }}</b-form-invalid-feedback>
        </b-form-group>
        <div v-if="form.in_treatment == 'yes'" class="pl-4">
          <b-form-group :label="label.treatment_setting">
            <b-form-radio-group
              v-model="form.treatment_setting"
              :options="options.treatment_setting"
              name="treatment_setting"
              :state="validateState('treatment_setting')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('treatment_setting') }}</b-form-invalid-feedback>
            <template v-if="form.treatment_setting == 'other'">
              <b-form-input :state="validateState('treatment_setting_other')" v-validate="{ required: true }" name="treatment_setting_other" v-model="form.treatment_setting_other" type="text"></b-form-input>
              <b-form-invalid-feedback>{{ veeErrors.first('treatment_setting_other') }}</b-form-invalid-feedback>
            </template>
          </b-form-group>
          <b-form-group :label="label.treatment_stage">
            <b-form-radio-group
              v-model="form.treatment_stage"
              :options="options.treatment_stage"
              name="treatment_stage"
              :state="validateState('treatment_stage')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('treatment_stage') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="label.treatment_reason">
            <b-form-checkbox-group
              v-model="form.treatment_reason"
              :options="options.treatment_reason"
              name="treatment_reason"
              :state="validateState('treatment_reason')" v-validate="{ required: true }"
              stacked
            ></b-form-checkbox-group>
            <b-form-invalid-feedback>{{ veeErrors.first('treatment_reason') }}</b-form-invalid-feedback>
            <template v-if="form.treatment_reason.includes('other')">
              <b-form-input :state="validateState('treatment_reason')" v-validate="{ required: true }" name="treatment_reason_other" v-model="form.treatment_setting_other" type="text"></b-form-input>
              <b-form-invalid-feedback>{{ veeErrors.first('treatment_reason') }}</b-form-invalid-feedback>
            </template>
          </b-form-group>
        </div>
        <b-form-group :label="label.highest_education">
            <b-form-radio-group
              v-model="form.highest_education"
              :options="options['highest_education_' + $i18n.locale]"
              name="highest_education"
              :state="validateState('highest_education')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('highest_education') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.religion">
            <b-form-radio-group
              v-model="form.religion"
              :options="options.religion"
              name="religion"
              :state="validateState('religion')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('religion') }}</b-form-invalid-feedback>
            <template v-if="form.religion == 'other'">
              <b-form-input :state="validateState('religion_other')" v-validate="{ required: true }" name="religion_other" v-model="form.religion_other" type="text"></b-form-input>
              <b-form-invalid-feedback>{{ veeErrors.first('religion_other') }}</b-form-invalid-feedback>
            </template>
        </b-form-group>
        <div class="pl-4" v-if="form.religion == 'protestant'">
          <b-form-group :label="label.religion_orientation">
              <b-form-radio-group
                v-model="form.religion_orientation"
                :options="options['religion_orientation_' + $i18n.locale]"
                name="religion_orientation"
                :state="validateState('religion_orientation')" v-validate="{ required: true }"
                stacked
              ></b-form-radio-group>
              <b-form-invalid-feedback>{{ veeErrors.first('religion_orientation') }}</b-form-invalid-feedback>
              <template v-if="form.religion_orientation == 'other'">
                <b-form-input :state="validateState('religion_orientation_other')" v-validate="{ required: true }" name="religion_orientation_other" v-model="form.religion_orientation_other" type="text"></b-form-input>
                <b-form-invalid-feedback>{{ veeErrors.first('religion_orientation_other') }}</b-form-invalid-feedback>
              </template>
          </b-form-group>
        </div>
        <b-form-group :label="label.religious_gatherings">
            <b-form-radio-group
              v-model="form.religious_gatherings"
              :options="options.religious_gatherings"
              name="religious_gatherings"
              :state="validateState('religious_gatherings')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('religious_gatherings') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.jesus_is">
            <b-form-radio-group
              v-model="form.jesus_is"
              :options="options.jesus_is"
              name="jesus_is"
              :state="validateState('jesus_is')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('jesus_is') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.i_pray">
            <b-form-radio-group
              v-model="form.i_pray"
              :options="options.i_pray"
              name="i_pray"
              :state="validateState('i_pray')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('i_pray') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="label.read_bible">
            <b-form-radio-group
              v-model="form.read_bible"
              :options="options.read_bible"
              name="read_bible"
              :state="validateState('read_bible')" v-validate="{ required: true }"
              stacked
            ></b-form-radio-group>
            <b-form-invalid-feedback>{{ veeErrors.first('read_bible') }}</b-form-invalid-feedback>
        </b-form-group>
        <div class="form-group">
          <legend>{{ label.matrix_1 }}</legend>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <td></td>
                <td v-for="(item, index) in options.matrix_1.options" :key="index">
                  {{ item.text }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in options.matrix_1.questions" :key="index">
                <td>{{ item.text }}</td>
                <td v-for="(oitem, oindex) in options.matrix_1.options" :key="oindex">
                  <b-form-radio v-model="form.matrix_1[index]" :name="'matrix_1_' + index" :value="oitem.value" :state="validateState('matrix_1_' + index)" v-validate="{ required: true }" />
                  <b-form-invalid-feedback>{{ veeErrors.first('matrix_1_' + index) }}</b-form-invalid-feedback>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="step == 2">
        <h1>
          {{ $t('The other questionnaires about religiosity/spirituality and coping.') }}<br>
          <small>{{ $t('Or scroll to the end of this page to the green button \'To the results\'') }}</small>
        </h1>
        <div class="form-group">
          <legend>{{ label.matrix_2 }}</legend>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <td></td>
                <td v-for="(item, index) in options.matrix_2.options" :key="index">
                  {{ item.text }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in options.matrix_2.questions" :key="index">
                <td>{{ item.text }}</td>
                <td v-for="(oitem, oindex) in options.matrix_2.options" :key="oindex">
                  <b-form-radio v-model="form.matrix_2[index]" :name="'matrix_2_' + index" :value="oitem.value" />
                  <b-form-invalid-feedback>{{ veeErrors.first('matrix_2_' + index) }}</b-form-invalid-feedback>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
          <legend>{{ label.matrix_3 }}</legend>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <td></td>
                <td v-for="(item, index) in options.matrix_3.options" :key="index">
                  {{ item.text }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in options.matrix_3.questions" :key="index">
                <td>{{ item.text }}</td>
                <td v-for="(oitem, oindex) in options.matrix_3.options" :key="oindex">
                  <b-form-radio v-model="form.matrix_3[index]" :name="'matrix_3_' + index" :value="oitem.value" />
                  <b-form-invalid-feedback>{{ veeErrors.first('matrix_3_' + index) }}</b-form-invalid-feedback>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
          <legend>{{ label.matrix_4 }}</legend>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <td></td>
                <td v-for="(item, index) in options.matrix_4.options" :key="index">
                  {{ item.text }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in options.matrix_4.questions" :key="index">
                <td>{{ item.text }}</td>
                <td v-for="(oitem, oindex) in options.matrix_4.options" :key="oindex">
                  <b-form-radio v-model="form.matrix_4[index]" :name="'matrix_4_' + index" :value="oitem.value" />
                  <b-form-invalid-feedback>{{ veeErrors.first('matrix_4_' + index) }}</b-form-invalid-feedback>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
          <legend>{{ label.matrix_5 }}</legend>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <td></td>
                <td v-for="(item, index) in options.matrix_5.options" :key="index">
                  {{ item.text }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in options.matrix_5.questions" :key="index">
                <td>{{ item.text }}</td>
                <td v-for="(oitem, oindex) in options.matrix_5.options" :key="oindex">
                  <b-form-radio v-model="form.matrix_5[index]" :name="'matrix_5_' + index" :value="oitem.value" />
                  <b-form-invalid-feedback>{{ veeErrors.first('matrix_5_' + index) }}</b-form-invalid-feedback>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
            <legend>{{ label.matrix_6 }}</legend>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <td></td>
                  <td v-for="(item, index) in options.matrix_6.options" :key="index">
                    {{ item.text }}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in options.matrix_6.questions" :key="index">
                  <td>{{ item.text }}</td>
                  <td v-for="(oitem, oindex) in options.matrix_6.options" :key="oindex">
                    <b-form-radio v-model="form.matrix_6[index]" :name="'matrix_6_' + index" :value="oitem.value" />
                    <b-form-invalid-feedback>{{ veeErrors.first('matrix_6_' + index) }}</b-form-invalid-feedback>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <b-form-group :label="label.questions">
          <b-form-textarea name="questions" v-model="form.questions"></b-form-textarea>
          <b-form-invalid-feedback>{{ veeErrors.first('questions') }}</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="clearfix">
        <b-button variant="secondary" size="lg" @click="previousPage" v-if="step > 1 && step < 3">{{ $t('Previous') }}</b-button>
        <b-button variant="primary" size="lg" @click="nextPage" v-if="step > 0 && step < 2" class="float-right">{{ $t('Next') }}</b-button>
        <b-button variant="primary" size="lg" @click="submitPage" v-if="step == 2" class="float-right">{{ $t('To the results') }}</b-button>
      </div>
      <b-alert variant="secondary" show class="mt-4 mb-4 text-center">
        {{ uuid }} - v1.7
      </b-alert>
    </div>
  </Web>
</template>

<script>
// @ is an alias to /src
import Web from '@/layouts/Web'
import axios from 'axios'

export default {
  data() {
    return {
      uuid: null,
      step: 0,
      label: {
        country: this.$t('The country I live in is'),
        age: this.$t('My age is'),
        gender: this.$t('I am'),
        marial_status: this.$t('I am'),
        in_treatment: this.$t('I am in professional treatment for my problems (e.g. with a psychologist or psychiatrist)'),
        treatment_setting: this.$t('a. In what kind of treatment setting'),
        treatment_stage: this.$t('b. In which stage is the treatment?'),
        treatment_reason: this.$t('c. What is the main reason for treatment (several answers are possible)'),
        highest_education: this.$t('The highest level of education that I completed or currently attend, is'),
        religion: this.$t('I am a member of or feel committed to:'),
        religion_orientation: this.$t('If protestant Church, what orientation?'),
        religious_gatherings: this.$t('I attend church services or religious gatherings'),
        jesus_is: this.$t('In my conviction Jesus is'),
        i_pray: this.$t('I pray'),
        read_bible: this.$t('I read the Bible or a different religious/ spiritual book'),
        matrix_1: this.$t('To which extent the following statements are applicable to you?'),
        matrix_2: this.$t('To what extent do you agree or disagree with each of the following statements describing your personal experience?'),
        matrix_3: this.$t('People have varying ways of coping with problems. Can you indicate how often you use the following methods in dealing with problems?'),
        matrix_4: this.$t('Can you indicate how often you use the following methods in dealing with problems? If I have problems'),
        matrix_5: this.$t('What I suffer from'),
        matrix_6: this.$t('The next part concerns the perception of yourself. How I feel generally'),
        questions: this.$t('Below there is space for comments/questions'),
      },
      options: {
        age: [
          { text: '10-19', value: '10-19' },
          { text: '20-29', value: '20-29' },
          { text: '30-39', value: '30-39' },
          { text: '40-49', value: '40-49' },
          { text: '50-59', value: '50-59' },
          { text: '60-69', value: '60-69' },
          { text: '70-79', value: '70-70' },
          { text: '80+', value: '80+' },
        ],
        gender: [
          { text: this.$t('Male'), value: 'male' },
          { text: this.$t('Female'), value: 'female' },
        ],
        marial_status: [
          { text: this.$t('Married'), value: 'married' },
          { text: this.$t('Living together'), value: 'living-together' },
          { text: this.$t('Widow/widower'), value: 'widow-widower' },
          { text: this.$t('Divorced'), value: 'divorced' },
          { text: this.$t('Single'), value: 'single' },
          { text: this.$t('Living with my parent(s)'), value: 'living-with-parents' },
        ],
        in_treatment: [
          { text: this.$t('Yes'), value: 'yes' },
          { text: this.$t('No'), value: 'no' },
        ],
        treatment_setting: [
          { text: this.$t('Social work, psychosocial support or psychological counseling'), value: 'counseling' },
          { text: this.$t('Mental health care, psychiatric or psychotherapeutic treatment'), value: 'treatment' },
          { text: this.$t('Other, namely'), value: 'other' },
        ],
        treatment_setting_other: null,
        treatment_stage: [
          { text: this.$t('Assessment'), value: 'assessment-stage' },
          { text: this.$t('Start of treatment'), value: 'start-stage' },
          { text: this.$t('Treatment going on'), value: 'on-going-stage' },
          { text: this.$t('Near the end of treatment'), value: 'end-stage' },
        ],
        treatment_reason: [
          { text: this.$t('Anxiety'), value: 'anxiety' },
          { text: this.$t('Depression'), value: 'depression' },
          { text: this.$t('Relational problems (e.g. with partner/ child)'), value: 'relational' },
          { text: this.$t('Personality disorder/ problems (e.g. borderline)'), value: 'personality' },
          { text: this.$t('Behavioral problems (e.g. aggression)'), value: 'behavioral' },
          { text: this.$t('Psychotic symptoms (e.g. delusions, hallucinations)'), value: 'psychotic' },
          { text: this.$t('Autism Spectrum Disorder'), value: 'autism' },
          { text: this.$t('Attention Deficit (Hyperactivity) Disorder'), value: 'attention' },
          { text: this.$t('Addiction problems (e.g. alcohol, drug abuse, gambling, pornography)'), value: 'addiction' },
          { text: this.$t('Eating disorder'), value: 'eating' },
          { text: this.$t('Other, namely'), value: 'other' },
        ],
        treatment_reason_other: null,
        highest_education_en: [
          { text: 'Primary School / Elementary School', value: 'primary-elementary-school' },
          { text: 'Lower Vocational / Technical Training', value: 'vocational-technical-training' },
          { text: 'Some High School', value: 'some-high-school' },
          { text: 'Intermediate Vocational / Technical training', value: 'intermediate-vocational' },
          { text: 'Graduated High School', value: 'graduated-high-school' },
          { text: 'Higher Vocational / Technical Training / Junior College', value: 'higher-vocational-technical-training-junior-college' },
          { text: 'College / University / Doctorate', value: 'college-university-doctorate' },
        ],
        highest_education_nl: [
          { text: 'Basisschool / Lagere school, VGLO', value: 'basisschool-lagere-school' },
          { text: 'Lager beroepsonderwijs (bijv. LTS, LEAO, huishoudschool, VMBO - basis- of kaderberoepsgerichte leerweg)', value: 'basis-kaderberoepsgerichte-leerweg' },
          { text: 'MAVO/ (M)ULO/ VMBO - gemengde of theoretische leerweg', value: 'gemengde-theoretische-leerweg' },
          { text: 'Middelbaar beroepsonderwijs (bijv. MTS/ MEAO)', value: 'middelbaar-beroepsonderwijs' },
          { text: 'HAVO, VWO, Lyceum, Atheneum, Gymnasium, HBS, MMS', value: 'hoger-algemeen-voortgezet-onderwijs' },
          { text: 'Hoger beroepsonderwijs (bijv. PABO, HTS)', value: 'hoger-beroepsonderwijs' },
          { text: 'Universitair onderwijs', value: 'universitair-onderwijs' },
        ],
        religion: [
          { text: this.$t('Protestant Church'), value: 'protestant' },
          { text: this.$t('Roman Catholic Church'), value: 'catholic' },
          { text: this.$t('Evangelical or Pentecostal Church'), value: 'evangelical-pentecostal' },
          { text: this.$t('Islam'), value: 'islam' },
          { text: this.$t('Hinduism'), value: 'hinduism' },
          { text: this.$t('Buddhism'), value: 'buddhism' },
          { text: this.$t('Humanism'), value: 'humanism' },
          { text: this.$t('Agnosticism'), value: 'agnosticism' },
          { text: this.$t('Atheism'), value: 'atheism' },
          { text: this.$t('Reformed Churches or Congregations'), value: 'reformed-churches-congregations' },
          { text: this.$t('Restored Reformed Church'), value: 'restored-reformed-church' },
          { text: this.$t('Other, namely'), value: 'other' },
        ],
        religion_orientation_en: [
          { text: 'Moderate mainline', value: 'moderate-mainline' },
          { text: 'Conservative', value: 'conservative' },
          { text: 'Liberal', value: 'liberal' },
          { text: 'Other, namely', value: 'other' },
        ],
        religion_orientation_nl: [
          { text: 'Midden Orthodox', value: 'midden-orthodox' },
          { text: 'Confessioneel', value: 'confessioneel' },
          { text: 'Gereformeerde Bond', value: 'gereformeerde' },
          { text: 'Vrijzinnig', value: 'vrijzinnig' },
        ],
        religious_gatherings: [
          { text: this.$t('Rarely or never'), value: 'rarely-never' },
          { text: this.$t('A few times a year'), value: 'few-times-year' },
          { text: this.$t('Once a month'), value: 'once-month' },
          { text: this.$t('A few times a month'), value: 'few-times-month' },
          { text: this.$t('Once every Sunday'), value: 'once-every-sunday' },
          { text: this.$t('Twice every Sunday'), value: 'twice-every-sunday' },
        ],
        jesus_is: [
            { text: this.$t('The Son of God and Redeemer of man'), value: 'son-of-god' },
            { text: this.$t('Not the Son of God, but sent by God'), value: 'sent-by-god' },
            { text: this.$t('A special man with extraordinary gifts'), value: 'special-man' },
            { text: this.$t('A normal human being like everyone else'), value: 'normal-human' },
            { text: this.$t('Only a legend'), value: 'only-legend' },
        ],
        i_pray: [
          { text: this.$t('Rarely or never'), value: 'rarely-never' },
          { text: this.$t('A few times a month'), value: 'few-times-month' },
          { text: this.$t('Once a week'), value: 'once-week' },
          { text: this.$t('A few times a week'), value: 'few-times-week' },
          { text: this.$t('Every day'), value: 'every-day' },
          { text: this.$t('Several times a day'), value: 'several-times-day' },
        ],
        read_bible: [
          { text: this.$t('Rarely or never'), value: 'rarely-never' },
          { text: this.$t('A few times a month'), value: 'few-times-month' },
          { text: this.$t('Once a week'), value: 'once-week' },
          { text: this.$t('A few times a week'), value: 'few-times-week' },
          { text: this.$t('Every day'), value: 'every-day' },
          { text: this.$t('Several times a day'), value: 'several-times-day' },
        ],
        matrix_1: {
          options: [
            { text: this.$t('Totally applicable'), value: 1 },
            { text: this.$t('Applicable'), value: 2 },
            { text: this.$t('Applicable/Not applicable'), value: 3 },
            { text: this.$t('Not applicable'), value: 4 },
            { text: this.$t('Totally not applicable'), value: 5 },
          ],
          questions: [
            { text: this.$t('In times of trial and tribulation I trust in God') },
            { text: this.$t('I pursue higher values such as love, truth and justice') },
            { text: this.$t('The development of my personality and my faith influence each other mutually') },
            { text: this.$t('I am looking for answers to existential questions about for example death, freedom, isolation, meaninglessness') },
            { text: this.$t('As a person I am only fully complete in a relationship with God') },
            { text: this.$t('I am willing to be accountable to God and my fellow humans about my way of life') },
            { text: this.$t('My faith influences all areas of my life') },
            { text: this.$t('I have the idea that I entrust myself more and more to God') },
            { text: this.$t('Out of my sense that God loves human beings, I pursue to love my fellow man') },
            { text: this.$t('My sense of self-esteem is connected to who I am and not so much to what I have') },
            { text: this.$t('The meaning and significance of my life is in my relationship with God') },
            { text: this.$t('My faith is oriented to values that transcend physical and social needs') },
            { text: this.$t('The experience of God in my life motivates me to decide for the good, even if this is difficult') },
            { text: this.$t('My personal freedom is limited by responsibility for God’s creation') },
            { text: this.$t('My behaviour is directed at both my own freedom and responsibility for others') },
            { text: this.$t('All aspects of my personality are comprehensively involved in my faith') },
            { text: this.$t('I believe sincerely, not mainly out of obligation or fear') },
            { text: this.$t('Knowing God’s love is fundamental for my life') },
            { text: this.$t('For me, praying for and doing justice belong together inextricably') },
            { text: this.$t('My faith is integrated with the other aspects of my life') },
            { text: this.$t('Through the sense of the entirely other I experience amazement') },
            { text: this.$t('My religion supports my sense of self-esteem and identity') },
            { text: this.$t('My life is an open and at the same time serious quest for God') },
          ]
        },
        matrix_2: {
          options: [
            { text: this.$t('Strongly agree'), value: 1 },
            { text: this.$t('Agree'), value: 2 },
            { text: this.$t('Neither agree nor disagree'), value: 3 },
            { text: this.$t('Disagree'), value: 4 },
            { text: this.$t('Strongly disagree'), value: 5 },
          ],
          questions: [
            { text: this.$t('I feel very fulfilled and satisfied with life') },
            { text: this.$t('I believe that God loves me and cares about me') },
            { text: this.$t('I have a personally meaningful relationship with God') },
            { text: this.$t('I don’t find much satisfaction in private prayer with God') },
            { text: this.$t('I don’t know who I am, where I came from, or where I’m going') },
            { text: this.$t('I feel unsettled about my future') },
            { text: this.$t('I believe there is some real purpose for my life') },
            { text: this.$t('I don’t enjoy much about life') },
            { text: this.$t('I don’t get much personal strength and support from my God') },
            { text: this.$t('I feel that life is a positive experience') },
            { text: this.$t('My relation with God contributes to my sense of well-being') },
            { text: this.$t('I believe that God is impersonal and not interested in my daily situations') },
            { text: this.$t('I feel good about my future') },
            { text: this.$t('I feel most fulfilled when I’m in close communion with God') },
            { text: this.$t('I don’t have a personally satisfying relationship with God') },
            { text: this.$t('I feel that life is full of conflict and unhappiness') },
            { text: this.$t('I believe that God is concerned about my problems') },
            { text: this.$t('Life doesn’t have much meaning') },
            { text: this.$t('I feel a sense of well-being about the direction my life is headed in') },
            { text: this.$t('My relationship with God helps me not to feel lonely') },
            { text: this.$t('In my life, I experience the presence of God') },
            { text: this.$t('My religious beliefs are what really lie behind my whole approach to life') },
            { text: this.$t('I try hard to carry my religion over into all other dealings in life') },
          ]
        },
        matrix_3: {
          options: [
            { text: this.$t('Never'), value: 5 },
            { text: this.$t('Rarely'), value: 4 },
            { text: this.$t('Sometimes'), value: 3 },
            { text: this.$t('Often'), value: 2 },
            { text: this.$t('Always'), value: 1 },
          ],
          questions: [
            { text: this.$t('When I am worried, earlier experiences make me trust that I will be shown a way out') },
            { text: this.$t('After a period of difficulties the deeper significance of my problems will be revealed to me') },
            { text: this.$t('When I find myself in times of trouble, I have faith in the eventual revelation of their meaning and purpose') },
            { text: this.$t('When I have problems, I trust that a solution will be presented to me') },
            { text: this.$t('When I wonder how to solve a problem, I trust that a solution will be shown to me in due course') },
            { text: this.$t('In difficult situations I trust that a way out will unfold') },
            { text: this.$t('In solving my problems, I am sometimes struck by the fact that things just fall into place') },
            { text: this.$t('In difficult situations I open myself to solutions that arise') },
          ]
        },
        matrix_4: {
          options: [
            { text: this.$t('Almost never'), value: 4 },
            { text: this.$t('Sometimes'), value: 3 },
            { text: this.$t('Often'), value: 2 },
            { text: this.$t('Almost always'), value: 1 },
          ],
          questions: [
            { text: this.$t('If I have problems, I look for a stronger connection with God') },
            { text: this.$t('If I have problems, I seek God’s love and care') },
            { text: this.$t('If I have problems, I seek help from God in letting go of my anger') },
            { text: this.$t('If I have problems, I try to put my plans into action together with God') },
            { text: this.$t('If I have problems, I try to see how God might be trying to strengthen me in this situation') },
            { text: this.$t('If I have problems, I ask forgiveness for my sins') },
            { text: this.$t('If I have problems, I focus on religion to stop worrying about my problems') },
            { text: this.$t('If I have problems, I wonder whether God has abandoned me') },
            { text: this.$t('If I have problems, I feel punished by God for my lack of devotion') },
            { text: this.$t('If I have problems, I wonder what I did for God to punish me') },
            { text: this.$t('If I have problems, I question God’s love for me') },
            { text: this.$t('If I have problems, I wonder whether my church has abandoned me') },
            { text: this.$t('If I have problems, I decide the devil made this happen') },
            { text: this.$t('If I have problems, I question the power of God') },
          ]
        },
        matrix_5: {
          options: [
            { text: this.$t('Almost never'), value: 4 },
            { text: this.$t('Sometimes'), value: 3 },
            { text: this.$t('Often'), value: 2 },
            { text: this.$t('Almost always'), value: 1 },
          ],
          questions: [
            { text: this.$t('I suffer from mental health problems') },
            { text: this.$t('I suffer from anxiety and/or panic attacks') },
            { text: this.$t('I suffer from compulsions and/or obsessions') },
            { text: this.$t('I suffer from relational problems (e.g. with partner/ child)') },
            { text: this.$t('I suffer from depressive feelings') },
            { text: this.$t('I suffer from my personality disorder (e.g. borderline)') },
            { text: this.$t('I suffer from my aggressive feelings/ behavior') },
            { text: this.$t('I suffer from my withdrawn behavior') },
            { text: this.$t('I suffer from delusions') },
            { text: this.$t('I suffer from hallucinations') },
            { text: this.$t('I suffer from difficulties in connecting and/or communicating with others') },
            { text: this.$t('I suffer from my restless and chaotic behavior') },
            { text: this.$t('I suffer from addiction to alcohol and/or drugs') },
            { text: this.$t('I suffer from addiction to gambling') },
            { text: this.$t('I suffer from addiction to pornography') },
            { text: this.$t('I suffer from my eating disorder') },
            { text: this.$t('I suffer from other problems') },
          ]
        },
        matrix_6: {
          options: [
            { text: this.$t('Almost never'), value: 4 },
            { text: this.$t('Sometimes'), value: 3 },
            { text: this.$t('Often'), value: 2 },
            { text: this.$t('Almost always'), value: 1 },
          ],
          questions: [
            { text: this.$t('I feel pleasant') },
            { text: this.$t('I feel nervous and restless') },
            { text: this.$t('I feel satisfied with myself') },
            { text: this.$t('I wish I could be as happy as others seem to be') },
            { text: this.$t('I feel like a failure') },
            { text: this.$t('I feel rested') },
            { text: this.$t('I am “calm, cool, and collected”') },
            { text: this.$t('I feel that difficulties are piling up so that I cannot overcome them') },
            { text: this.$t('I worry too much over something that really doesn’t matter') },
            { text: this.$t('I am happy') },
            { text: this.$t('I have disturbing thoughts') },
            { text: this.$t('I lack self-confidence') },
            { text: this.$t('I feel secure') },
            { text: this.$t('I make decisions easily') },
            { text: this.$t('I feel inadequate') },
            { text: this.$t('I am content') },
            { text: this.$t('Some unimportant thought runs through my mind and bothers me') },
            { text: this.$t('I take disappointments so keenly that I can’t put them out of my mind') },
            { text: this.$t('I am a steady person') },
            { text: this.$t('I get in a state of tension or turmoil as I think over my recent concerns and interest') },
          ]
        }
      },
      form: {
        country: null,
        age: null,
        gender: null,
        marial_status: null,
        in_treatment: null,
        treatment_setting: null,
        treatment_stage: null,
        treatment_reason: [],
        highest_education: null,
        religion: null,
        religion_orientation: null,
        religion_orientation_other: null,
        religion_other: null,
        religious_gatherings: null,
        jesus_is: null,
        i_pray: null,
        read_bible: null,
        matrix_1: [],
        matrix_2: [],
        matrix_3: [],
        matrix_4: [],
        matrix_5: [],
        matrix_6: [],
        questions: null,
      }
    }
  },
  components: {
    Web,
  },
  methods: {
    previousPage() {
      this.step = this.step - 1;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    nextPage() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          
          return;
        }

        this.step = this.step + 1;

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      })
    },
    submitPage() {
      axios.post("https://api.maturereligiosityscale.com/result", { 'form': this.form, 'locale': this.$i18n.locale, 'uuid': this.uuid })
      .then(response => {
          response;
          this.$router.push('/' + this.$i18n.locale + '/result/' + this.uuid)
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    uuidv4() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
  },
  created() {
    this.uuid = this.uuidv4();
  }
}
</script>

<style>
 .table td{
  padding:10px !important;
 }
</style>